import React from "react";

export default function ArrowEnter() {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.767578 5.16227H0.314979V5.61487H0.767578V5.16227ZM7.92665 5.4823C8.1034 5.30555 8.1034 5.01898 7.92665 4.84223L5.04633 1.96191C4.86958 1.78516 4.58301 1.78516 4.40626 1.96191C4.22951 2.13866 4.22951 2.42523 4.40626 2.60198L6.96655 5.16227L4.40626 7.72256C4.22951 7.89931 4.22951 8.18588 4.40626 8.36263C4.58301 8.53938 4.86958 8.53938 5.04633 8.36263L7.92665 5.4823ZM0.767578 5.61487H7.60662V4.70967H0.767578V5.61487ZM1.22018 5.16227V0.381836H0.314979V5.16227H1.22018Z"
        fill="currentColor"
      />
    </svg>
  );
}
