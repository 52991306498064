import React from "react";
import { graphql } from "gatsby";
import Page from "../../components/page";
import MyOrders from "../../containers/user/containers/MyOrders";
import ProtectedRoute from "../../containers/user/components/ProtectedRoute";

const UserOrdersPage = ({ data }: { data: Queries.MyOrdersQueryDataQuery }) => {
  return (
    <Page {...data.datoCmsWebPage} container="fluid">
      <ProtectedRoute component={MyOrders} />
    </Page>
  );
};

export const query = graphql`
  query MyOrdersQueryData($locale: String!) {
    datoCmsWebPage(path: { eq: "user/orders" }, locale: { eq: $locale }) {
      ...WebPageFragment
    }
  }
`;

export default UserOrdersPage;
