import { OrderType } from "../../../../types/airgraft-express";
import moment from "moment-timezone";

type GroupedOrders = { open: OrderType[]; past: OrderType[] };

export function groupOrders(orders: OrderType[]): GroupedOrders {
  return orders.reduce(
    (accumulator, order) => {
      const isOld = moment().diff(moment(order.createdDate), "days") > 7;
      const isComplete = order.status == "COMPLETED";
      const isCancelled =
        order.status == "CANCELLED" || order.status === "DECLINED";

      if (isComplete || isCancelled || isOld) {
        return { ...accumulator, past: [...accumulator.past, order] };
      } else {
        return { ...accumulator, open: [...accumulator.open, order] };
      }
    },
    { open: [], past: [] } as GroupedOrders
  );
}
