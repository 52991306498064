import { Box } from "@material-ui/core";
import React from "react";
import useTranslate from "../../../../../hooks/use-translate";
import OrderConfirmationOrderStatus from "../../../../express-delivery/OrderConfirmation/components/OrderConfirmationOrderStatus";
import { OrderType } from "../../../../../types/airgraft-express";
import { Link } from "gatsby";
import ArrowEnter from "./ArrowEnter";
import useLocalizedLocationPath from "../../../../../hooks/use-localized-location-path";
import { getOrderDetailsPath } from "../../../../../helpers/path";

type Props = {
  order: OrderType;
  isFetching?: boolean;
};

export default function OrderItem({ order, isFetching }: Props) {
  const t = useTranslate();
  const getLocalizedPath = useLocalizedLocationPath();

  const detailsPath = order
    ? getLocalizedPath(getOrderDetailsPath(order))
    : null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      mb="2px"
      paddingX={{ xs: 3, sm: 4 }}
      paddingY={{ xs: 2, sm: 3 }}
      bgcolor="grey.100"
      borderRadius="3px"
    >
      <OrderConfirmationOrderStatus order={order} isFetching={isFetching} />
      <Box mt={1}>
        <Link
          to={detailsPath}
          style={{
            fontSize: `1.2rem`,
            fontWeight: 600,
            lineHeight: `1.6rem`,
            letterSpacing: `1.5px`,
            textTransform: `uppercase`
          }}
        >
          <Box mr={0.5} component="span">
            <ArrowEnter />
          </Box>
          {t("accountSettings.section.orders.view")}
        </Link>
      </Box>
    </Box>
  );
}
