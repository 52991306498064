import { Box, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { SHOP_PRODUCTS_PATH } from "../../../../helpers/url";
import useOrderHistory from "../../../../hooks/use-order-history";
import useTranslate from "../../../../hooks/use-translate";
import EmptyMessage from "../../components/EmptyMessage";
import UserAccountLayout from "../../components/UserAccountLayout";
import OrderItem from "./OrderItem";
import { groupOrders } from "./utils";

export default function MyOrders() {
  const t = useTranslate();

  // Order history state
  const { data: ordersData, error, isFetching } = useOrderHistory();
  const groupedOrders = ordersData?.orders
    ? groupOrders(ordersData?.orders)
    : null;

  const hasOpenOrders =
    groupedOrders && groupedOrders.open && groupedOrders?.open.length > 0;
  const hasPastOrders =
    groupedOrders && groupedOrders.past && groupedOrders?.past.length > 0;
  const hasNoOrders =
    groupedOrders?.open.length === 0 && groupedOrders?.past.length === 0;

  return (
    <UserAccountLayout
      containerWidth="wide"
      title={t("accountSettings.myorders")}
      showLoading={!ordersData && isFetching}
    >
      <Box>
        {hasNoOrders && (
          <EmptyMessage
            button={{
              label: t("shop.browseAllProducts"),
              to: SHOP_PRODUCTS_PATH
            }}
          >
            {t(`accountSettings.orders.noOrder`)}
          </EmptyMessage>
        )}

        {/* Open orders */}
        {hasOpenOrders && (
          <Box marginBottom={{ xs: 3, md: 6 }}>
            <Box marginBottom={{ xs: 3, md: 4 }}>
              <Typography variant="h5">
                {t("accountSettings.openorders")}
              </Typography>
            </Box>

            {groupedOrders.open.map(order => (
              <OrderItem
                key={order.orderLogId}
                order={order}
                isFetching={isFetching}
              />
            ))}
          </Box>
        )}

        {/* Past orders */}
        {hasPastOrders && (
          <Box>
            <Box marginBottom={{ xs: 2, md: 4 }}>
              <Typography variant="h6">
                {t("accountSettings.pastorders")}
              </Typography>
            </Box>

            {groupedOrders.past.map(order => (
              <OrderItem
                key={order.orderLogId}
                order={order}
                isFetching={isFetching}
              />
            ))}
          </Box>
        )}

        {/* Error */}
        {error && (
          <Alert severity="error">{t("accountSettings.orders.error")}</Alert>
        )}
      </Box>
    </UserAccountLayout>
  );
}
