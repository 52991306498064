import { useQuery, useQueryClient } from "react-query";
import { getUserOrderHistory } from "../helpers/express-delivery/order";

export default function useOrderHistory() {
  const queryClient = useQueryClient();
  return useQuery("orders", () => getUserOrderHistory(), {
    staleTime: 1000 * 15, // 15 seconds,
    refetchOnWindowFocus: true,
    retry: true,
    onSuccess: data => {
      if (data) {
        // Pre-fill cache for order details
        data.orders?.forEach(order => {
          if (order.orderLogId) {
            queryClient.setQueryData(["order", order.orderLogId], order);
          }
        });
      }
    }
  });
}
